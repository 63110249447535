import useTranslation from 'hooks/useTranslation';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Stack, { type StackProps } from '@mui/material/Stack';
import { type ForwardedRef, forwardRef, type ReactElement } from 'react';

interface ErrorComponentProps extends StackProps {
    errorMessage?: string,
    onReload?: () => void
}

const ErrorComponent = ({ onReload, errorMessage, ...props }: ErrorComponentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();

    return (
        <>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
                <Stack
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={2}
                    width={'100%'}
                    my={'auto'}
                    overflow={'hidden'}
                    {...props}
                    ref={ref}
                >
                    <Typography sx={{ pb: 1 }} color={'error'}>
                        {errorMessage || t('layout.error.page')}
                    </Typography>
                    {onReload ?
                        <LoadingButton variant={'outlined'} color={'error'} onClick={onReload}>
                            {t('layout.button.reload')}
                        </LoadingButton>
                        :
                        <></>
                    }
                </Stack>
            </Stack>
        </>
    );
};
const ErrorComponentWithRef = forwardRef(ErrorComponent) as
    (p: ErrorComponentProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;

export default ErrorComponentWithRef;
export type {
    ErrorComponentProps
};

