import CircularProgress from '@mui/material/CircularProgress';
import Fade, { type FadeProps } from '@mui/material/Fade';
import Stack, { type StackProps } from '@mui/material/Stack';
import { forwardRef, type ForwardedRef, type ReactElement } from 'react';

interface LoadingComponentProps extends StackProps {
    FadeProps?: Partial<FadeProps>
}
const LoadingComponent = ({ children, FadeProps, ...props }: LoadingComponentProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
                <Fade in={true} {...FadeProps}>
                    <Stack
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={2}
                        width={'100%'}
                        height={'100%'}
                        my={'auto'}
                        overflow={'hidden'}
                        {...props}
                        ref={ref}
                    >
                        <CircularProgress />
                        {children}
                    </Stack>
                </Fade>
            </Stack>
        </>
    );
};

const LoadingComponentWithRef = forwardRef(LoadingComponent) as
    (p: StackProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;

export default LoadingComponentWithRef;
export type {
    LoadingComponentProps
};

