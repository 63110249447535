import Box from '@mui/material/Box';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImmersivePageLayout, { type ImmersivePageLayoutProps } from 'components/layout/ImmersivePageLayout';
import ProtectAuth, { type ProtectAuthProps } from 'components/layout/ProtectAuth';
import { type NavMenuProviderProps } from 'contexts/NavMenuContext';
import useHistory from 'hooks/useHistory';
import { useRouter } from 'next/router';
import { type ReactElement, useCallback, useEffect } from 'react';
import useLoadingProgress from 'shared/hooks/loadingProgress';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('./Footer'));
const PatternTop = dynamic(() => import('components/patterns/PatternTop'));
const NavMenuProvider = dynamic(() => import('contexts/NavMenuContext'));

interface LayoutProps {
    children: ReactElement | ReactElement[],
    headerType?: 'search' | 'return' | 'returnItem' | 'none',
    withSearchFilters?: boolean,
    pattern?: boolean,
    desktopFooter?: boolean,
    mobileFooter?: boolean,
    onReturn?: () => void,
    returnTo?: string | any,
    title?: string,
    text?: string,
    NavMenuProps?: Partial<NavMenuProviderProps>,
    PageProtectProps?: Partial<ProtectAuthProps>,
    ImmersivePageLayoutProps?: Partial<ImmersivePageLayoutProps>
}

const Layout = ({
    pattern = true,
    children,
    headerType = 'search',
    withSearchFilters,
    desktopFooter = true,
    mobileFooter = false,
    onReturn,
    returnTo,
    title,
    text,
    NavMenuProps,
    PageProtectProps,
    ImmersivePageLayoutProps
}: LayoutProps) => {
    const router = useRouter();
    const { start, done } = useLoadingProgress();

    const theme: Theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const onRouteChangeStart = useCallback(() => {
        return start();
    }, [start]);

    const onRouteChangeComplete = useCallback(() => {
        const timeout = setTimeout(() => {
            done();
        }, 100);
        return () => {
            if (timeout) {
                return clearTimeout(timeout);
            }
        };
    }, [done]);

    useEffect(() => {
        router.events.on('routeChangeStart', onRouteChangeStart);
        router.events.on('routeChangeComplete', onRouteChangeComplete);
        router.events.on('routeChangeError', onRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
            router.events.off('routeChangeComplete', onRouteChangeComplete);
            router.events.off('routeChangeError', onRouteChangeComplete);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onRouteChangeStart, onRouteChangeComplete]);

    const { canGoBack, goBack } = useHistory();
    const handleReturn = useCallback(() => {
        if (onReturn) {
            return onReturn();
        }
        if (returnTo) {
            return router.replace(returnTo);
        }
        if (canGoBack) {
            return goBack();
        }
        return router.push('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnTo, onReturn, canGoBack, goBack]);


    return (
        <>
            <ImmersivePageLayout {...ImmersivePageLayoutProps}>
                <NavMenuProvider
                    headerType={headerType}
                    onReturn={handleReturn}
                    title={title}
                    text={text}
                    withSearchFilters={withSearchFilters}
                    {...NavMenuProps}
                >
                    <>
                        <Box
                            sx={{
                                position: 'relative',
                                zIndex: 1,
                                flexWrap: 'wrap',
                                display: 'flex',
                                flex: 1,
                                width: '100%',
                                maxWidth: '100vw',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                backgroundColor: 'transparent',
                                color: 'primary.main'
                            }}
                        >
                            {pattern ?
                                <Box
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        opacity: 0.25,
                                        width: '35%',
                                        maxWidth: { xs: 362, md: 194, lg: 194 },
                                        ['& svg']: {
                                            width: '100%',
                                            height: 'auto',
                                            display: 'block'
                                        }
                                    }}
                                >
                                    <PatternTop color={'childTertiary'} />
                                </Box>
                                :
                                null
                            }
                            <ProtectAuth {...PageProtectProps}>
                                <>{children}</>
                            </ProtectAuth>
                        </Box>
                        {(mobile && mobileFooter) || (!mobile && desktopFooter) ? <Footer /> : <></>}
                    </>
                </NavMenuProvider>
            </ImmersivePageLayout>
        </>
    );
};

export default Layout;
export type {
    LayoutProps
};

