import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { init } from '@socialgouv/matomo-next';
import Layout from 'components/layout/Layout';
import ApiProvider from 'contexts/ApiContext';
import AuthProvider from 'contexts/AuthContext';
import LanguageProvider from 'contexts/LanguageContext';
import 'maplibre-gl/dist/maplibre-gl.css';
import type { NextComponentType, NextPage, NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import { API_URLS } from 'services/api';
import { LoadingProgressProvider } from 'shared/hooks/loadingProgress';
import { SWRConfig } from 'swr';
import GlobalCssPriority from 'utils/GlobalCssPriority';
import { createEmotionCache } from 'utils/createEmotionCache';
import FvTheme from 'utils/theme';

const AlertProvider = dynamic(() => import('contexts/AlertContext'), { ssr: false });
const MyVadrouilleEditorLayout = dynamic(() => import('components/layout/MyVadrouilleEditorLayout'), { ssr: false });
const ImportVadrouilleProvider = dynamic(() => import('contexts/ImportVadrouilleContext'), { ssr: false });
const CartProvider = dynamic(() => import('contexts/CartContext'), { ssr: true });
const HistoryManagerProvider = dynamic(() => import('contexts/HistoryManagerContext'), { ssr: false });

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL || '';
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID || '';
const CONTAINER_PATH = process.env.NEXT_PUBLIC_MATOMO_CONTAINER_PATH || '';

const LanguageSyncRouter = dynamic(() => import('components/layout/language/LanguageSyncRouter'), { ssr: false });

interface LayoutProps {
    children: ReactElement | ReactElement[]
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type NextPageWithAuth = NextPage & {
    loading: ReactElement
    Layout?: (data: LayoutProps) => JSX.Element //NextComponentType<NextPageContext>
};
type NextComponentWithAuth = NextComponentType<NextPageContext> & Partial<NextPageWithAuth>;
interface FvAppProps extends AppProps {
    emotionCache?: EmotionCache,
    fallback?: object
    Component: NextComponentWithAuth,
    pageProps: any
}

const ChildrenLayout = ({
    layoutProps,
    pageProps,
    Component
}: { layoutProps: any, pageProps: any, Component: NextComponentWithAuth }) => {
    return (
        <>
            {layoutProps?.component && layoutProps?.component == 'MyVadrouilleEditorLayout' ?
                <MyVadrouilleEditorLayout {...(layoutProps || null)}>
                    <Component {...pageProps} />
                </MyVadrouilleEditorLayout>
                :
                <Layout {...(layoutProps || null)}>
                    <Component {...pageProps} />
                </Layout>
            }
        </>
    );
};

const FvApp = ({
    Component,
    emotionCache = clientSideEmotionCache,
    fallback,
    pageProps: { layoutProps, ...pageProps }
}: FvAppProps) => {

    useEffect(() => {
        if (MATOMO_URL && MATOMO_SITE_ID) {
            init({
                url: MATOMO_URL,
                siteId: MATOMO_SITE_ID,
                jsTrackerFile: CONTAINER_PATH ? CONTAINER_PATH : 'matomo.js'
            });
        }
    }, []);

    return (
        <LanguageProvider>
            <GlobalCssPriority>
                <CacheProvider value={emotionCache}>
                    <Head>
                        <title>{process.env.NEXT_PUBLIC_APP_NAME || 'French Vadrouilles'}</title>
                        <meta name={'viewport'} content={'initial-scale=1, width=device-width, viewport-fit=cover'} />
                        <meta name={'description'}
                            content={process.env.NEXT_PUBLIC_APP_DESCRIPTION || 'French Vadrouilles'} />
                    </Head>
                    <SWRConfig value={{ fallback }}>
                        <ThemeProvider theme={FvTheme}>
                            <CssBaseline />
                            <LoadingProgressProvider>
                                <AlertProvider>
                                    <HistoryManagerProvider>
                                        <ApiProvider
                                            prefetch={[]}
                                        >
                                            <AuthProvider
                                                loginUrl={API_URLS.AUTH}
                                                askResetUrl={API_URLS.AUTH_RESET_ASK}
                                                resetUrl={API_URLS.AUTH_RESET_PASSWORD}
                                            >
                                                <CartProvider>
                                                    {
                                                        layoutProps?.withImportVadrouilleContext ?
                                                            <ImportVadrouilleProvider>
                                                                <ChildrenLayout
                                                                    layoutProps={layoutProps}
                                                                    pageProps={pageProps}
                                                                    Component={Component}
                                                                />
                                                            </ImportVadrouilleProvider>
                                                            :
                                                            <ChildrenLayout
                                                                layoutProps={layoutProps}
                                                                pageProps={pageProps}
                                                                Component={Component}
                                                            />
                                                    }
                                                </CartProvider>
                                            </AuthProvider>
                                        </ApiProvider>
                                    </HistoryManagerProvider>
                                </AlertProvider>
                            </LoadingProgressProvider>

                            <LanguageSyncRouter />
                        </ThemeProvider>
                    </SWRConfig>
                </CacheProvider>
            </GlobalCssPriority>
        </LanguageProvider>
    );
};

export default FvApp;
export type {
    LayoutProps
};

