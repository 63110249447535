import { FormatIcu } from '@tolgee/format-icu';
import { LanguageStorage, Tolgee, TolgeeProvider, useTolgeeSSR } from '@tolgee/react';
import { type ReactElement } from 'react';

const tolgee = Tolgee()
    .use(FormatIcu())
    .use(LanguageStorage())
    .init({
        defaultLanguage: 'fr',
        availableLanguages: ['fr', 'en']
    });

const staticData = {
    fr: () => import('../i18n/translations.fr.json'),
    en: () => import('../i18n/translations.en.json')
};

interface LanguageProviderProps {
    children: ReactElement | ReactElement[]
}
const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const ssrTolgee = useTolgeeSSR(tolgee, undefined, staticData);

    return (
        <TolgeeProvider tolgee={ssrTolgee}>
            <>{children}</>
        </TolgeeProvider>
    );
};

export default LanguageProvider;
export {
    LanguageProvider
};
export type {
    LanguageProviderProps
};

