import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import ErrorComponent from 'components/utils/ErrorComponent';
import LoadingComponent from 'components/utils/LoadingComponent';
import useApi from 'hooks/useApi';
import useTranslation from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useMemo, type ReactElement } from 'react';
import useSWR from 'swr';
import { useIsFirstRender } from 'usehooks-ts';

interface ProtectAuthProps {
    needAuth?: boolean,
    needUnauth?: boolean,
    redirectUri?: string,
    onChange?: (allowed: boolean) => void,
    children?: ReactElement,
    returnCurrentPageAfterLogin?: boolean
}

const ProtectAuth = ({ children, needAuth, needUnauth, redirectUri, returnCurrentPageAfterLogin = true, ...props }: ProtectAuthProps) => {
    const { authenticated, loading } = useApi();
    const { t } = useTranslation();
    const router = useRouter();
    const isFirstRender = useIsFirstRender();

    const allowed = useMemo(() => {
        if (needAuth) { return authenticated; }
        if (needUnauth) { return !authenticated && !loading; }
        return true;
    }, [authenticated, needUnauth, needAuth, loading]);

    const fetcher: (_: any) => Promise<{ allowed: boolean }> = useCallback((args: any) => {
        return new Promise((resolve, reject) => {
            if (!args.allowed && !args.loading) {
                if (router?.query?.redirectUri) {
                    return router.push(decodeURIComponent(router.query.redirectUri as string))
                        .finally(() => reject({ message: 'redirect' }));
                } else if (redirectUri) {
                    return router.push(`${redirectUri}${returnCurrentPageAfterLogin ? `?redirectUri=${encodeURIComponent(router.asPath)}` : ''}`)
                        .finally(() => reject({ message: 'redirect' }));
                }
            }
            return resolve(args);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectUri, returnCurrentPageAfterLogin, router]);

    const { data: { allowed: _controlledAllowed } = {}, isValidating } = useSWR<{ allowed: boolean }>({
        allowed,
        path: router.basePath,
        authenticated,
        loading: loading || !router.isReady
    }, fetcher, {
        fallbackData: { allowed: allowed },
        revalidateOnMount: true,
        revalidateIfStale: true,
        shouldRetryOnError: false,
        dedupingInterval: 0
    });

    return (
        <>
            <Stack flex={1} alignItems={'stretch'} justifyContent={'center'} maxWidth={'100%'}>
                <Fade in={_controlledAllowed} timeout={0} mountOnEnter={true} unmountOnExit={isFirstRender || !loading}>
                    <Stack flex={1}>
                        {children}
                    </Stack>
                </Fade>
                <Collapse in={!_controlledAllowed && (loading || isValidating)} mountOnEnter={true} unmountOnExit={true} timeout={{ exit: 0 }}>
                    <Box>
                        <Fade in={true} timeout={0} mountOnEnter={true} unmountOnExit={true}>
                            <LoadingComponent sx={{
                                height: '100vh',
                                width: '100wh'
                            }} />
                        </Fade>
                    </Box>
                </Collapse>
                <Collapse in={!_controlledAllowed && !(loading || isValidating)} mountOnEnter={true} unmountOnExit={true}>
                    <Box>
                        <Fade in={true} mountOnEnter={true} unmountOnExit={true}>
                            <ErrorComponent errorMessage={needAuth ?
                                t('layout.error.auth.needAuth') :
                                needUnauth ?
                                    t('layout.error.auth.needUnauth') :
                                    t('layout.error.page')
                            } />
                        </Fade>
                    </Box>
                </Collapse>
            </Stack>
        </>
    );
};
export default ProtectAuth;
export type {
    ProtectAuthProps
};

