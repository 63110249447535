import { type CityProps } from './cities';
import { type FrenchVadrouillesTagProps, type Id, type SubThemeProps, type TagProps, type ThemeProps } from './descriptors';
import { type UserProps } from './users';

interface ImageProps {
    id: string;
    path?: string;
    credits?: string;
    title?: string;
}
interface EstablishmentImageProps {
    id: string;
    index: number;
    image: ImageProps;
}

interface EstablishmentLanguageProps {
    id: number;
    establishment_id: number;
    name?: string,
    language_id: number;
    description?: string;
    title?: string;
}

interface EstablishmentProps {
    id?: number;
    name: string;
    description?: string,
    logo?: string;
    image?: ImageProps;
    address: string;
    additional_address?: string;
    city_id: number;
    city?: {
        id: number;
        post_code: string;
        name: string;
    };
    phone: string;
    is_pay_phone?: boolean;
    access_type_id?: number;
    access_type?: {
        id: number;
        name: string;
    };

    pivot?:{
        link: string
    };
    users?: UserProps[],
    languages?: EstablishmentLanguageProps[];
    language?: EstablishmentLanguageProps;
    cities?: CityProps[];
    updated_at?: Date,
    cgv_path?: string;
}

interface EstablishmentVadrouilleursProps extends EstablishmentProps {
    first_displayed: 'pois' | 'vadrouilles';
    contact_image?: ImageProps;
    main_discover_bloc?: DiscoverBlocProps;
    other_discover_blocs?: DiscoverBlocProps[];
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
    website?: string;
}

interface EstablishmentContextProps {
    logo?: string;
    name?: string;
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
    secondary_contrast_text: string;
    tertiary_contrast_text: string;
}

interface DiscoverBlocLanguagesProps extends Id {
    language_id: string | number;
    title?: string;
    description?: string;
    [key: string]: any;
}

interface DiscoverBlocProps {
    id: number,
    languages: DiscoverBlocLanguagesProps[],
    language: DiscoverBlocLanguagesProps,
    images?: ImageProps[],
    main_image?: { image: ImageProps },

    index: number,
    tags?: TagProps[],
    frenchvadrouilles_tags?: FrenchVadrouillesTagProps[],
    themes?: ThemeProps[],
    sub_themes?: SubThemeProps[],
    establishment_id?: number
}

const defaultEstablishment: EstablishmentProps = {
    name: '',
    address: '',
    city_id: 0,
    phone: '',
    is_pay_phone: false,
    access_type_id: 0,
};

const defaultEstablishmentVadrouilleurs: EstablishmentVadrouilleursProps = {
    ...defaultEstablishment,
    first_displayed: 'vadrouilles',
    primary_color: '#0A1A27',
    secondary_color: '#FFAD0F',
    tertiary_color: '#AF3924',
};

const defaultEstablishmentContext: EstablishmentContextProps = {
    logo: '',
    primary_color: '#333333',
    secondary_color: '#777777',
    tertiary_color: '#999999',
    secondary_contrast_text: 'primary',
    tertiary_contrast_text: 'white',
};

export { defaultEstablishment, defaultEstablishmentContext, defaultEstablishmentVadrouilleurs };
export type {
    DiscoverBlocLanguagesProps, DiscoverBlocProps, EstablishmentContextProps, EstablishmentLanguageProps, EstablishmentProps, EstablishmentVadrouilleursProps,
    ImageProps
};

