import { useSelectedLanguage } from 'hooks/useTranslation';
import fetchWrapper from 'services/fetchWrapper';
import { type FetchProps } from 'shared/services/types';
import {
    createContext,
    type Dispatch,
    type ReactElement,
    type SetStateAction,
    useCallback,
    useMemo,
    useState
} from 'react';
import { mutate } from 'swr';
import { useEffectOnce } from 'usehooks-ts';

interface ApiContextProps {
    getApi: (_: FetchProps) => Promise<any>,
    postApi: (_: FetchProps) => Promise<any>,
    deleteApi: (_: FetchProps) => Promise<any>,
    setFixedHeaders: Dispatch<SetStateAction<{ [p: string]: any } | undefined>>
}

const defaultApiContext: ApiContextProps = {
    setFixedHeaders: () => null,
    deleteApi: () => Promise.reject(),
    getApi: () => Promise.reject(),
    postApi: () => Promise.reject()
};

const ApiContext = createContext<ApiContextProps>(defaultApiContext);

interface ApiProviderProps {
    children: ReactElement,
    initialFixedHeaders?: { [key: string]: any },
    prefetch?: string[]
}

const ApiProvider = ({ children, initialFixedHeaders, prefetch = [] }: ApiProviderProps) => {
    const [fixedHeaders, setFixedHeaders] = useState(initialFixedHeaders);
    const { lang: currentLang } = useSelectedLanguage();

    const propsWithLang = useCallback(({ opt, lang, ...rest }: FetchProps) => ({
        ...rest,
        opt: {
            ...opt,
            headers: {
                ...opt?.headers,
                ['Content-Language']: lang ? lang : currentLang
            }
        }
    }) as FetchProps, [currentLang]);

    const fetchWithHeaders = useCallback(async (props: FetchProps, _fetcher: (_: FetchProps) => Promise<any>) => {
        if (!props.url) {
            return Promise.reject('fetch needs an url');
        }
        const { opt, ...rest } = propsWithLang(props);
        return _fetcher({
            ...rest,
            opt: {
                ...opt,
                headers: {
                    ...fixedHeaders,
                    ...opt?.headers
                }
            }
        });
    }, [fixedHeaders, propsWithLang]);

    const postApi = useCallback((props: FetchProps) => fetchWithHeaders(props, fetchWrapper.postApi), [fetchWithHeaders]);
    const getApi = useCallback((props: FetchProps) => fetchWithHeaders(props, fetchWrapper.getApi), [fetchWithHeaders]);
    const deleteApi = useCallback((props: FetchProps) => fetchWithHeaders(props, fetchWrapper.deleteApi), [fetchWithHeaders]);

    useEffectOnce(() => {
        prefetch.map(url => mutate({ url }, getApi({ url }).then(res => res)));
    });

    const contextValue = useMemo(() => ({
        postApi,
        getApi,
        deleteApi,
        setFixedHeaders
    }), [postApi, getApi, deleteApi, setFixedHeaders]);

    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
};

export default ApiProvider;
export {
    ApiContext,
    ApiProvider
};
export type {
    ApiContextProps,
    ApiProviderProps
};
