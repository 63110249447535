import { StyledEngineProvider } from '@mui/material/styles';
import type { ReactElement } from 'react';

const GlobalCssPriority = ({ children }: { children?: ReactElement | ReactElement[] }) => {
    return (
        <StyledEngineProvider injectFirst>
            {children}
        </StyledEngineProvider>
    );
};

export default GlobalCssPriority;