import { type ReactElement, useCallback } from 'react';
import { type BoxProps } from '@mui/material/Box';
import ImmersivePageProvider from 'contexts/ImmersivePageContext';
import useSWR from 'swr';
import { type NextRouter, withRouter } from 'next/router';


interface ImmersivePageLayoutProps extends BoxProps {
    children?: ReactElement,
    enabled?: true,
    router: NextRouter
}

const ImmersivePageLayout = ({
    children,
    enabled,
    router,
    ...props
}: ImmersivePageLayoutProps) => {

    const getEstablishmentId = useCallback(({ path, query, enabled: e }: any) => {
        if (!e) {return 0;}
        if (path.startsWith('/establishments')) {
            return query.id;
        } else if (query.establishment_id) {
            return query.establishment_id;
        }
        return new URLSearchParams(document.location.search).get('establishment_id');
    }, []);
    const { data: establishmentId } = useSWR<number >({ path: router.pathname, query: router.query, enabled }, getEstablishmentId, {
        fallbackData: 0,
        revalidateOnMount: true,
        revalidateIfStale: true,
        keepPreviousData: true
    });

    return (
        <>
            <ImmersivePageProvider
                establishmentId={parseInt((establishmentId || '0') as string)}
                enabled={!!(establishmentId)}
            >
                {children}
            </ImmersivePageProvider>
        </>
    );
};

export default withRouter(ImmersivePageLayout);
export type {
    ImmersivePageLayoutProps
};