import { ApiContext, type ApiContextProps } from 'contexts/ApiContext';
import { useContext } from 'react';

const useBaseApi = () => useContext(ApiContext) as ApiContextProps;

export default useBaseApi;
export {
    useBaseApi
};

