import useBaseApi from 'hooks/useBaseApi';
import useMutateSWRPartialKey from 'hooks/useMutateSWRPartialKey';
import { useSelectedLanguage } from 'hooks/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useMemo, useRef, type ReactElement } from 'react';
import { API_URLS } from 'services/api';
import { fetchWrapper, type Cancellable, type Redirectable } from 'services/fetchWrapper';
import { type ErrorResponseType, type FetchProps, type LanguagesProps } from 'services/types';
import { useLoadingProgress } from 'shared/hooks/loadingProgress';
import useSWR from 'swr';
import { useBoolean, useLocalStorage } from 'usehooks-ts';

const LoginHintModal = dynamic(() => import('components/login/LoginHintModal'), { ssr: false });


interface VerboseProps {
    verbose?: boolean,
    hideLoadingScreen?: boolean
}
interface RightsProps {
    [key: string]: { [key2: string]: boolean }
}
interface UserTokenProps {
    id: number,
    profile_picture: string,
    spokenLanguage: LanguagesProps,
    first_name?: string,
    last_name?: string,
    email?: string
}
interface FvToken {
    access_token: string,
    refresh_token: string,
    received_at: number,
    expires_in: number
}
interface LoginProps extends Redirectable, Cancellable, VerboseProps {
    username: string,
    password: string,
}
type RefreshIntervalProps = Partial<FvToken & { deduce_ms: number }>;
interface AskResetPasswordProps extends Redirectable, Cancellable, VerboseProps {
    email: string
}
interface ResetPasswordProps extends Redirectable, Cancellable, VerboseProps {
    email: string,
    password: string,
    password_confirmation: string,
    token: string
}
interface LogoutProps extends Redirectable, Cancellable, VerboseProps { }
interface ShowLoginHintOpt { redirectUri: string }

interface AuthContextProps {
    authenticated: boolean,
    getApi: (_: FetchProps) => Promise<any>,
    postApi: (_: FetchProps) => Promise<any>,
    deleteApi: (_: FetchProps) => Promise<any>,
    fetchWithToken: (props: FetchProps, _fetcher: (_: FetchProps) => Promise<any>) => Promise<any>,
    login: (props: LoginProps) => Promise<unknown>,
    logout: (props?: LogoutProps) => Promise<unknown>,
    refresh: (props?: any) => Promise<unknown>,
    loading: boolean,
    right: RightsProps,
    user: UserTokenProps,
    askResetPassword: (props: AskResetPasswordProps) => Promise<unknown>,
    resetPassword: (props: ResetPasswordProps) => Promise<unknown>,
    showLoginHint: (opt?: Partial<ShowLoginHintOpt>) => void;
}
const defaultAuthContext: AuthContextProps = {
    authenticated: false,
    login: Promise.reject,
    logout: Promise.reject,
    loading: true,
    askResetPassword: Promise.reject,
    resetPassword: Promise.reject,
    getApi: Promise.reject,
    postApi: Promise.reject,
    deleteApi: Promise.reject,
    fetchWithToken: Promise.reject,
    refresh: Promise.reject,
    right: {},
    user: { id: 0, profile_picture: '', spokenLanguage: { id: 1, code: 'fr' } },
    showLoginHint: Promise.reject
};
const AuthContext = createContext<AuthContextProps>(defaultAuthContext);


interface OtherProps {
    right: RightsProps,
    user: UserTokenProps
}
type LoginResponse = FvToken & OtherProps;
const defaultLoginResponse: LoginResponse = {
    access_token: '',
    expires_in: 0,
    received_at: 0,
    refresh_token: '',
    right: {},
    user: { id: 0, profile_picture: '', spokenLanguage: { id: 1, code: 'fr' } }
};
interface AuthProviderProps {
    loginUrl?: string,
    logoutUrl?: string,
    askResetUrl?: string,
    resetUrl?: string,
    postApiFetcher?: (_: FetchProps) => Promise<LoginResponse>,
    children: ReactElement
}

const AuthProvider = ({
    loginUrl = API_URLS.AUTH,
    logoutUrl = API_URLS.LOGOUT,
    askResetUrl = API_URLS.AUTH_ASK_RESET,
    resetUrl = API_URLS.AUTH_RESET_PASSWORD,
    postApiFetcher = fetchWrapper.postApi,
    children
}: AuthProviderProps) => {
    const { clearCache } = useMutateSWRPartialKey();
    const router = useRouter();
    const { setLang } = useSelectedLanguage();
    const { getApi: baseGetApi, postApi: basePostApi, deleteApi: baseDeleteApi } = useBaseApi();

    const authPayloadFormatter = useCallback(({ url, body, opt }: FetchProps) => {
        return ({
            url,
            body: {
                ...body,
                client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
                client_secret: process.env.NEXT_PUBLIC_AUTH_CLIENT_SECRET
            },
            opt: {
                ...opt,
                credentials: 'include'
            }
        });
    }, []);

    const refreshToken = useRef<string | null>(null);
    const authFetcher: (_: FetchProps) => Promise<LoginResponse> = useCallback(({ body, redirectUri, ...args }: FetchProps) => {
        return new Promise((resolve, reject) => {
            postApiFetcher(authPayloadFormatter({
                ...args,
                body: {
                    ...(body ? body : ({
                        refresh_token: refreshToken.current,
                        grant_type: 'refresh_token'
                    }))
                }
            })).then(res => {
                refreshToken.current = res.refresh_token;
                return { ...res, received_at: Date.now() };
            })
                .then(resolve)
                .catch(reject);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authPayloadFormatter, postApiFetcher]);

    const refreshInterval = useCallback(({ received_at = Date.now(), expires_in = -1, deduce_ms = 500 }: RefreshIntervalProps = {}) => {
        return (received_at + (expires_in * 1000) - deduce_ms) - Date.now();
    }, []);

    const isAccessTokenValid = useCallback((token?: Partial<FvToken>) => {
        if (!token?.received_at || !token?.expires_in) { return false; }
        return refreshInterval(token) > 0;
    }, [refreshInterval]);

    /**
     * fonction en charge de l'ajout des headers d'authentification
     * TODO: ajouter les allowed_origins
     */
    const authHeaders = useCallback(async (loginResponse?: LoginResponse) => {
        if (loginResponse?.access_token && isAccessTokenValid(loginResponse)) {
            return Promise.resolve({ headers: { Authorization: `Bearer ${loginResponse.access_token}` } });
        } else {
            return Promise.reject({ message: 'Token not valid' });
        }
    }, [isAccessTokenValid]);

    const handleSuccess = useCallback((res: LoginResponse) => {
        if (res?.user?.spokenLanguage?.code) {
            setLang(res.user.spokenLanguage.code);
        }
        return res;
    }, [setLang]);

    const handleError = useCallback((err: ErrorResponseType) => {
        refreshToken.current = null;
    }, []);

    const { data: authData, isValidating, error, mutate } = useSWR<LoginResponse>({
        url: loginUrl
    }, authFetcher, {
        fallbackData: defaultLoginResponse,
        revalidateOnMount: true,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        keepPreviousData: true,
        refreshInterval: latestData => refreshInterval(latestData),
        onError: err => handleError(err),
        onSuccess: res => handleSuccess(res)
    });

    const { start, done } = useLoadingProgress();
    useEffect(() => { return isValidating ? start() : done(); }, [start, done, isValidating]);


    const fetchWithToken = useCallback(async (props: FetchProps, _fetcher: (_: FetchProps) => Promise<any>) => {
        return new Promise((resolve) => {
            return authHeaders(authData)
                .then(({ headers }) => {
                    resolve(_fetcher({
                        ...props,
                        opt: {
                            ...props.opt,
                            headers: {
                                ...props.opt?.headers,
                                ...headers
                            }
                        }
                    }));
                }).catch(() => {
                    //permet de faire les appels sans être connecté
                    resolve(_fetcher(props));
                });
        });
    }, [authData, authHeaders]);


    const getApi = useCallback((props: FetchProps) => fetchWithToken(props, baseGetApi), [fetchWithToken, baseGetApi]);
    const postApi = useCallback((props: FetchProps) => fetchWithToken(props, basePostApi), [fetchWithToken, basePostApi]);
    const deleteApi = useCallback((props: FetchProps) => fetchWithToken(props, baseDeleteApi), [fetchWithToken, baseDeleteApi]);

    //! ne pas mettre setAuthEvent en dependance des callback -> boucle infinie
    const [authEvent, setAuthEvent] = useLocalStorage('auth-event', '');
    const triggerAuthEvent = useCallback((type: 'login' | 'logout') => {
        return Promise.resolve()
            .then(() => setAuthEvent(`${type}.${performance.now()}`))
            .then(() => setAuthEvent(''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const login = useCallback(({ controller, redirectUri, ...args }: LoginProps) => {
        const authFetch = authFetcher({ url: loginUrl, redirectUri: redirectUri, body: { ...args, grant_type: 'password' }, opt: { signal: controller?.signal } } as FetchProps);
        return authFetch
            .then((res) => {
                router.push(decodeURIComponent(redirectUri || ''), undefined, { shallow: false });
                return res;
            })
            .then(res => mutate(authFetch, { revalidate: true }))
            .then(() => triggerAuthEvent('login'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutate, authFetcher, loginUrl]);

    const logout = useCallback(({ controller, redirectUri, ...args }: LogoutProps = {}) => {
        const refreshLocalData = () => {
            refreshToken.current = null;
            return mutate(defaultLoginResponse, { revalidate: true });
        };
        const refreshDistantData = () => getApi(authPayloadFormatter({
            url: logoutUrl,
            body: { ...args, grant_type: 'password' },
            opt: { signal: controller?.signal }
        }));
        return refreshDistantData()
            .then(() => triggerAuthEvent('logout'))
            .finally(() => {
                return Promise.all([clearCache(), refreshLocalData()]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getApi, authPayloadFormatter, logoutUrl, mutate, clearCache, triggerAuthEvent]);

    useEffect(() => {
        if (![undefined, null, ''].includes(authEvent)) {
            switch (authEvent.split('.')[0]) {
                case 'login': {
                    mutate();
                    break;
                }
                case 'logout': {
                    if (isAccessTokenValid(authData)) {
                        logout();
                    }
                    break;
                }
                default: break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authEvent]);

    const askResetPassword = useCallback(({ controller, email }: AskResetPasswordProps) => {
        const body = {
            email
        };
        return postApiFetcher(authPayloadFormatter({ url: askResetUrl, body, opt: { signal: controller?.signal } }));
    }, [postApiFetcher, authPayloadFormatter, askResetUrl]);

    const resetPassword = useCallback(({ controller, email, password, password_confirmation, token }: ResetPasswordProps) => {
        const body = {
            email,
            password,
            password_confirmation,
            token
        };
        return postApiFetcher(authPayloadFormatter({ url: resetUrl, body, opt: { signal: controller?.signal } }));
    }, [resetUrl, postApiFetcher, authPayloadFormatter]);

    const loginHintOpen = useBoolean(false);
    const loginHintOpt = useRef<Partial<ShowLoginHintOpt>>();

    const contextValue: AuthContextProps = useMemo(() => ({
        getApi,
        postApi,
        deleteApi,
        fetchWithToken,
        login,
        logout,
        askResetPassword,
        resetPassword,
        showLoginHint: (opt) => {
            loginHintOpt.current = opt;
            loginHintOpen.setTrue();
        },
        refresh: mutate,
        loading: isValidating,
        get authenticated() { return isAccessTokenValid(authData); },
        get user() { return authData?.user || defaultLoginResponse.user; },
        get right() { return authData?.right || defaultLoginResponse.right; }
    }), [authData, isValidating, isAccessTokenValid, getApi, postApi, deleteApi, fetchWithToken, login, logout, askResetPassword, resetPassword, loginHintOpen, mutate]);

    return (
        <>
            <AuthContext.Provider value={contextValue}>
                <>
                    <LoginHintModal
                        open={loginHintOpen.value}
                        onClose={loginHintOpen.setFalse}
                        redirectUri={!['/'].includes(router.asPath) ? encodeURIComponent(router.asPath) : undefined}
                        {...loginHintOpt?.current}
                    />
                    {children}
                </>
            </AuthContext.Provider>
        </>
    );
};

export default AuthProvider;
export {
    AuthContext
};
export type {
    AskResetPasswordProps,
    AuthContextProps,
    AuthProviderProps,
    FvToken,
    LoginProps,
    LogoutProps,
    ResetPasswordProps,
    RightsProps,
    UserTokenProps
};

