import { type ErrorResponseType, type FetchProps } from 'shared/services/types';
import { type Dispatch, type SetStateAction } from 'react';


interface HandleResponseProps {
    response: Response | any;
    setNeedRefresh? : Dispatch<SetStateAction<boolean>> 
}




interface Redirectable {
    redirectUri?: string
}

interface Cancellable {
    controller?: AbortController
}



const handleResponse = ({ response, setNeedRefresh } : HandleResponseProps) => {
    if (!response.ok) {
        if ([401].includes(response.status)) {
            if (setNeedRefresh) {
                setNeedRefresh(true);
            }
            return Promise.reject({
                message: `layout.error.network.${response.status || 'unknown'}`,
                escapeTranslateKey: true,
                severity: 'error',
                statusCode: 401
            });
        } else if (!response.status || [500, 403, 404].includes(response.status)) {
            return Promise.reject({
                message: response?.message ?? `layout.error.network.${response.status || 'unknown'}`,
                escapeTranslateKey: true,
                severity: 'error',
                statusCode: response.status || 500
            });
        } else {
            return response.text().then((text: string) => {
                const data = text && JSON.parse(text);
                return Promise.reject({
                    ...data,
                    originalMessage: data.message, 
                    message: `layout.error.network.${response.status || 'unknown'}`,
                    escapeTranslateKey: true,
                    severity: 'error',
                    statusCode: response.status
                });
            });
        }
    }
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (data.error) {
            return Promise.reject({
                ...data,
                message: `layout.error.network.${response.status || 'unknown'}`,
                escapeTranslateKey: true,
                severity: 'error'
            });
        }
        return Promise.resolve(data);
    });
};

const getApi = ({ url, opt, setNeedRefresh, controller }: FetchProps) => {
    if (!url) return Promise.reject();
    const requestOptions = {
        method: 'GET',
        signal: controller?.signal,
        ...opt
    };
    return fetch(url, requestOptions as RequestInit)
        .catch((response) => {return handleResponse({ response, setNeedRefresh });})
        .then((response) => {return handleResponse({ response, setNeedRefresh });});
};

const bodyToFormData = (body: FetchProps['body'], form = new FormData()) => {
    Object.entries({ ...(body || {}) }).map(([key, value]) => {
        switch (typeof value) {
            case 'undefined': break;
            case 'string': form.append(key, value); break;
            case 'boolean': form.append(key, value ? '1' : '0'); break;
            case 'number': form.append(key, value.toString()); break;
            case 'object': {
                if (value === null) break;
                const subForm = new FormData();
                const subValues = bodyToFormData(Array.isArray(value) ? value.map((v, index) => (v)) : value, subForm);
                Array.from(subValues.entries())?.map(([subKey, subValue]) => {
                    form.append(`${key}.${subKey}`, subValue);
                });
                break;
            }
            default: break;
        }
    });
    return form;
};

const postApi = ({ url, body, opt, setNeedRefresh, controller }: FetchProps) => {
    if (!url) return Promise.reject();
    const { headers, escapeContentType, ...rest } = opt || {};
    const requestOptions = {
        method: 'POST',
        signal: controller?.signal,
        headers: {
            ...headers,
            ...(escapeContentType ? {} : { ['Content-Type']: 'application/json' }),
            Accept: 'application/json'
        },
        body: escapeContentType ? body : JSON.stringify(body),
        ...rest
    };
    return fetch(url, requestOptions as RequestInit)
        .catch((response) => {return handleResponse({ response, setNeedRefresh });})
        .then((response) => {return handleResponse({ response, setNeedRefresh });});
};

const putApi = ({ url, body, opt, setNeedRefresh, controller }: FetchProps) => {
    if (!url) return Promise.reject();
    const { headers, ...rest } = opt || {};
    const requestOptions = {
        method: 'PUT',
        signal: controller?.signal,
        headers: { 
            ...headers 
        },
        body: JSON.stringify(body),
        ...rest
    };
    return fetch(url, requestOptions as RequestInit)
        .catch((response) => {return handleResponse({ response, setNeedRefresh });})
        .then((response) => {return handleResponse({ response, setNeedRefresh });});
};

const deleteApi = ({ url, opt, setNeedRefresh, controller }: FetchProps) => {
    if (!url) return Promise.reject();
    const requestOptions = {
        method: 'DELETE',
        signal: controller?.signal,
        ...opt
    };
    return fetch(url, requestOptions as RequestInit) 
        .catch((response) => {return handleResponse({ response, setNeedRefresh });})
        .then((response) => {return handleResponse({ response, setNeedRefresh });});
};


/**
 * TODO ajouter param opt callback param abort
 * !appel reseau sous forme fetch(...).catch(...).then(...)
 * Le .catch(...) s'occupe de gerer dans le cas où le serveur est indisponible
 * Il doit être avant le .then(...) pour ne pas surcharger dans le cas où le .then(...) renvoie un Promise.reject
 */

const fetchWrapper = {
    getApi,
    postApi,
    putApi,
    deleteApi
};

export default fetchWrapper;
export { fetchWrapper, bodyToFormData };
export type {
    FetchProps,
    ErrorResponseType,
    Redirectable,
    Cancellable
};

