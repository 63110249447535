import { createContext, ReactElement, useCallback, useMemo } from 'react';
import useApi from 'hooks/useApi';
import useSWR from 'swr';
import { API_URLS } from 'services/api';
import { defaultEstablishmentContext, type EstablishmentContextProps } from 'shared/services/establishments_base';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FvTheme from '../utils/theme';
import { useRouter } from 'next/router';

const ImmersivePageContext = createContext<ImmersivePageProviderProps>({ logo: '', establishmentId: 0, establishmentName: '' });

interface ImmersivePageProviderProps {
    establishmentId?: number,
    children?: ReactElement,
    enabled?: boolean,
    logo?: string,
    establishmentName?: string
}

const ImmersivePageProvider = ({ enabled, establishmentId, children }: ImmersivePageProviderProps) => {
    const { getApi } = useApi();
    const router = useRouter();

    const fetcher = useCallback(({ url, id, disabled }: any) => {
        return (!disabled && typeof id === 'number') ? getApi({ url }) : null;
    }, [getApi]);

    const { data } = useSWR<EstablishmentContextProps | null>( establishmentId ? { url: `${API_URLS.ESTABLISHMENTS}/${establishmentId}/context`, id: establishmentId, disabled: !enabled } : null, fetcher, {
        fallbackData: defaultEstablishmentContext,
        revalidateOnMount: true,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        keepPreviousData: true,
        onError: () => {
            const { establishment_id, ...query } = router.query;
            router.replace({
                pathname: router.pathname,
                query: {
                    ...query
                }
            }, undefined, { shallow: false });
        },
        shouldRetryOnError: false
    });

    const getContrastHex = useCallback((colorName: string | undefined) => {
        return (colorName === 'primary'
            ? data?.primary_color
            : (colorName === 'black'
                ? '#252525'
                : '#FAFAFA'
            )
        );
    }, [data?.primary_color]);

    const immersiveTheme = useMemo(() => data ? createTheme(FvTheme, {
        palette: {
            childPrimary: {
                main: data?.primary_color
            },
            childSecondary: {
                main: data?.secondary_color,
                contrastText: getContrastHex(data?.secondary_contrast_text)
            },
            childTertiary: {
                main: data?.tertiary_color,
                contrastText: getContrastHex(data?.tertiary_contrast_text)
            }
        }
    }) : FvTheme, [data, getContrastHex]);

    const contextValue = useMemo(() => ({
        logo: data?.logo || '',
        establishmentId: establishmentId || 0,
        establishmentName: data?.name || ''
    }), [data, establishmentId]);

    return enabled && establishmentId ? (
        <ImmersivePageContext.Provider value={contextValue}>
            <ThemeProvider theme={immersiveTheme}>
                {children}
            </ThemeProvider>
        </ImmersivePageContext.Provider>
    ) : <>{children}</>;
};
export default ImmersivePageProvider;
export {
    ImmersivePageProvider,
    ImmersivePageContext
};
export type {
    ImmersivePageProviderProps
};
