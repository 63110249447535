import { useRouter } from 'next/router';
import { createContext, useCallback, useMemo, useState, type ReactElement } from 'react';
import { useEffectOnce } from 'usehooks-ts';

interface GoBackOpt {
    fallbackPath: string
}
interface HistoryManagerContextProps {
    canGoBack: boolean,
    goBack: (opt?: Partial<GoBackOpt>) => Promise<void>,
    history: string[]
}
const defaultHistoryManagerContext: HistoryManagerContextProps = {
    canGoBack: false,
    history: [],
    goBack: Promise.reject
};
const HistoryManagerContext = createContext<HistoryManagerContextProps>(defaultHistoryManagerContext);

interface HistoryManagerProviderProps {
    children: ReactElement
}

const HistoryManagerProvider = ({ children }: HistoryManagerProviderProps) => {
    const router = useRouter();
    const [history, setHistory] = useState<string[]>([router.asPath]);

    const handleRouteChange = useCallback((url: string, { shallow = false, ...opt } = {}) => {
        if (!shallow) {
            setHistory( prevState => {
                return [...prevState, url];
            });
            return true;
        }
        return true;
    }, []);

    useEffectOnce(() => {
        router.beforePopState((state) => {
            setHistory( prevState => {
                return [...prevState.slice(0, -2), state.url];
            });
            return true;
        });

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    });

    const goBack = useCallback(({ fallbackPath = '/' }: Partial<GoBackOpt> = {}) => {
        return Promise.resolve()
            .then(() => {
                if (history.length > 1) { router.back(); } else {
                    if (fallbackPath) {
                        router.push(fallbackPath);
                    }
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const contextValue = useMemo(() => ({
        history,
        get canGoBack() {
            return history.length >= 1;
        },
        goBack
    }), [history, goBack]);

    return (
        <HistoryManagerContext.Provider value={contextValue}>
            {children}
        </HistoryManagerContext.Provider>
    );
};

export default HistoryManagerProvider;
export {
    HistoryManagerContext
};
export type {
    HistoryManagerContextProps,
    HistoryManagerProviderProps
};
