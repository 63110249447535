//@ts-nocheck
import { alpha, createTheme, darken, lighten, type Theme } from '@mui/material/styles';
import commonTheme from 'shared/utils/theme';


declare module '@mui/material/AppBar' {
    interface AppBarPropsColorOverrides {
        neutral: true;
    }
}

declare module '@mui/material/StepConnector' {
    interface StepConnectorPropsColorOverrides {
        neutral: true;
        disabled: true;
        tertiary: true;
        childPrimary: true;
        childSecondary: true;
        childTertiary: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        neutral: true;
        disabled: true;
        tertiary: true;
        childPrimary: true;
        childSecondary: true;
        childTertiary: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true
    }
}

// Create a theme instance.
//https://javascript.plainenglish.io/extend-material-ui-theme-in-typescript-a462e207131f
const baseTheme: Theme = createTheme(commonTheme, {
    palette: {
        mode: 'light',
        primary: { 
            main: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27',
            contrastText: '#FFFFFF'
        },
        secondary: { 
            main: process.env.NEXT_PUBLIC_THEME_SECONDARY || '#FFAD0F',
            contrastText: '#0A1A27'
        },
        tertiary: { 
            main: process.env.NEXT_PUBLIC_THEME_TERTIARY || '#AF3924',
            contrastText: '#FFFFFF'
        },
        childPrimary: {
            main: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27',
            contrastText: '#FFFFFF'
        },
        childSecondary: {
            main: process.env.NEXT_PUBLIC_THEME_SECONDARY || '#FFAD0F',
            contrastText: '#0A1A27'
        },
        childTertiary: {
            main: process.env.NEXT_PUBLIC_THEME_TERTIARY || '#AF3924',
            contrastText: '#FFFFFF'
        },
        background: {
            paper: '#FFF',
            default: '#FFF',
            dark: '#000'
        },
        error: {
            main: '#DD0000',
            dark: '#902613'
        },
        success: {
            main: '#56C798'
        },
        neutral: {
            main: '#fff' 
        },
        weloginPrimary: {
            main: '#44A0C2'
        },
        disabled: {
            main: '#E0E0E0',
            dark: '#707070'
        },
        tonalOffset: 0.5
    },
    typography: {
        fontSize: 16,
        h1: {
            fontSize: '28px',
            fontWeight: '400',
            ['@media screen and (min-width: 900px)']: {
                fontSize: '38px',
                fontWeight: '600'
            },
            fontFamily: 'new-spirit, Times New Roman, serif',
            lineHeight: 1,
            color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27'
        },
        h2: {
            fontSize: '22px',
            ['@media screen and (min-width: 900px)']: {
                fontSize: '30px'
            },
            fontWeight: 400,
            fontFamily: 'new-spirit, Times New Roman, serif',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            color: process.env.NEXT_PUBLIC_THEME_TERTIARY || '#AF3924'
        },
        h3: {
            fontSize: '18px',
            ['@media screen and (min-width: 900px)']: {
                fontSize: '24px'
            },
            fontFamily: 'new-spirit, Times New Roman, serif',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27'
        },
        h4: {
            fontSize: '14px',
            fontWeight: '500',
            ['@media screen and (min-width: 900px)']: {
                fontSize: '16px',
                fontWeight: '600'
            },
            fontFamily: 'new-spirit, Times New Roman, serif',
            textTransform: 'uppercase',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
            color: '#848484'
        },
        body1: {
            fontSize: '14px',
            lineHeight: 1.1,
            ['@media screen and (min-width: 900px)']: {
                fontSize: '16px'
            },
            color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27'
        },
        body2: {
            fontSize: '12px',
            lineHeight: 1.1,
            ['@media screen and (min-width: 900px)']: {
                fontSize: '14px'
            },
            color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27'
        },
        body3: {
            fontSize: '12px',
            fontFamily: 'new-spirit, Times New Roman, serif',
            lineHeight: 1.1,
            ['@media screen and (min-width: 900px)']: {
                fontSize: '14px'
            },
            color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27'
        }
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: 18
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    boxShadow: 'none',
                    lineHeight: 1.1,
                    ['&:hover, &:focus']: {
                        boxShadow: 'none'
                    }
                },
                sizeSmall: ({ theme }: { theme:Theme }) => ({
                    fontSize: '13px'
                }),
                sizeMedium: ({ theme }: { theme:Theme }) => ({
                    minHeight: 28
                }),
                sizeLarge: ({ theme }: { theme:Theme }) => ({
                    minHeight: 38
                }),
                containedPrimary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover, &:focus']: {
                        backgroundColor: darken(theme.palette.primary.main, 0.25)
                    }
                }),
                containedSecondary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover, &:focus']: {
                        backgroundColor: lighten(theme.palette.secondary.main, 0.2)
                    }
                }),
                outlinedSecondary: ({ theme }: { theme:Theme }) => ({
                    borderColor: darken(theme.palette.secondary.main, 0.25),
                    color: darken(theme.palette.secondary.main, 0.25),
                    ['&:hover, &:focus']: {
                        borderColor: darken(theme.palette.secondary.main, 0.25),
                        color: darken(theme.palette.secondary.main, 0.25),
                        backgroundColor: alpha(theme.palette.secondary.main, 0.1)
                    }
                }),
                containedTertiary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover, &:focus']: {
                        backgroundColor: darken(theme.palette.tertiary.main, 0.15)
                    }
                }),
                containedNeutral: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.primary.main,
                    ['&:hover, &:focus']: {
                        backgroundColor: lighten(theme.palette.primary.main, 0.8)
                    }
                }),
                containedSuccess: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.neutral.main,
                    ['&:hover, &:focus']: {
                        backgroundColor: '#39a87a'
                    }
                }),
                outlinedSuccess: ({ theme }: { theme:Theme }) => ({
                    borderColor: darken(theme.palette.success.main, 0.25),
                    color: darken(theme.palette.success.main, 0.25),
                    ['&:hover, &:focus']: {
                        borderColor: darken(theme.palette.success.main, 0.25),
                        color: darken(theme.palette.success.main, 0.25),
                        backgroundColor: alpha(theme.palette.success.main, 0.1)
                    }
                })
            }
        },
        MuiFab: {
            styleOverrides: {
                sizeSmall: ({ theme }: { theme:Theme }) => ({
                    fontSize: '13px'
                }),
                sizeMedium: ({ theme }: { theme:Theme }) => ({
                    fontSize: '14px'
                }),
                default: ({ theme }: { theme:Theme }) => ({
                    backgroundColor: theme.palette.neutral.main,
                    color: theme.palette.primary.main
                }),
                secondary: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.neutral.main,
                    ['&:hover']: {
                        backgroundColor: lighten(theme.palette.secondary.main, 0.1)
                    }
                }),
                tertiary: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.neutral.main,
                    ['&:hover']: {
                        backgroundColor: darken(theme.palette.tertiary.main, 0.1)
                    }
                }),
                neutral: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.primary.main
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    lineHeight: 1,
                    ...(ownerState.color) === 'childSecondary' && {
                        backgroundColor: lighten(theme.palette.childSecondary.main, 0.4),
                        ['&.MuiChip-clickable:hover']: {
                            backgroundColor: theme.palette.childSecondary.main
                        }
                    }
                }),
                colorDefault: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.primary.main
                }), 
                colorSecondary: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.secondary.contrastText,
                    ['&.MuiChip-clickable']: {
                        backgroundColor: lighten(theme.palette.secondary.main, 0.4)
                    },
                    ['&.MuiChip-clickable:hover']: {
                        backgroundColor: theme.palette.secondary.main
                    },
                    ['&.MuiChip-clickable.MuiChip-outlined']: {
                        backgroundColor: 'transparent'
                    },
                    ['&.MuiChip-clickable.MuiChip-outlined:hover']: {
                        backgroundColor: lighten(theme.palette.secondary.main, 0.9)
                    }
                }),
                outlinedSecondary: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.secondary.main
                }),
                deletableColorSecondary: ({ theme }: { theme:Theme }) => ({
                    ['& .MuiChip-deleteIcon']: {
                        color: alpha(theme.palette.primary.main, 0.8)
                    },
                    ['& .MuiChip-deleteIcon:hover']: {
                        color: theme.palette.primary.main
                    },
                    ['&.MuiChip-outlined .MuiChip-deleteIcon']: {
                        color: alpha(theme.palette.secondary.main, 0.7)
                    },
                    ['&.MuiChip-outlined .MuiChip-deleteIcon:hover']: {
                        color: theme.palette.secondary.main
                    }
                })
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27',
                    ['&.Mui-disabled']: {
                        color: lighten(process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27', 0.5)
                    },
                    ['&.Mui-checked']: {
                        color: process.env.NEXT_PUBLIC_THEME_SECONDARY || '#AF3924'
                    },
                    ['&.Mui-checked.Mui-disabled']: {
                        color: lighten(process.env.NEXT_PUBLIC_THEME_SECONDARY || '#AF3924', 0.5)
                    },
                    ['&.Mui-checked + .MuiSwitch-track']: {
                        backgroundColor: process.env.NEXT_PUBLIC_THEME_SECONDARY || '#AF3924'
                    },
                    ['&.Mui-checked.Mui-disabled + .MuiSwitch-track']: {
                        backgroundColor: process.env.NEXT_PUBLIC_THEME_SECONDARY || '#AF3924'
                    }
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    minWidth: 40,
                    padding: '3px 0 0 0',
                    color: process.env.NEXT_PUBLIC_THEME_PRIMARY || '#0A1A27',
                    ['& .MuiBottomNavigationAction-label']: {
                        fontSize: '14px',
                        marginTop: '2px'
                    },
                    ['@media screen and (min-width: 900px) { & .MuiBottomNavigationAction-label']: {
                        fontSize: '16px',
                        marginTop: '6px',
                        minWidth: 100
                    }
                }
            }
        }
    },
    zIndex: {
        drawer: 1200,
        modal: 1300,
        appBar: 1299,
        fab: 1501
    }
});


const FvTheme: Theme = createTheme(baseTheme, {
    palette: {
        primary: {
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_900 ? { 900: process.env.NEXT_PUBLIC_THEME_PRIMARY_900 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_800 ? { 800: process.env.NEXT_PUBLIC_THEME_PRIMARY_800 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_700 ? { 700: process.env.NEXT_PUBLIC_THEME_PRIMARY_700 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_600 ? { 600: process.env.NEXT_PUBLIC_THEME_PRIMARY_600 } : {} },
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_500 ? { 500: process.env.NEXT_PUBLIC_THEME_PRIMARY_500 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_400 ? { 400: process.env.NEXT_PUBLIC_THEME_PRIMARY_400 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_300 ? { 300: process.env.NEXT_PUBLIC_THEME_PRIMARY_300 } : { 300: '#125281' } },
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_200 ? { 200: process.env.NEXT_PUBLIC_THEME_PRIMARY_200 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_100 ? { 100: process.env.NEXT_PUBLIC_THEME_PRIMARY_100 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_PRIMARY_50 ? { 50: process.env.NEXT_PUBLIC_THEME_PRIMARY_50 } : {} } 
        },
        secondary: {
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_900 ? { 900: process.env.NEXT_PUBLIC_THEME_SECONDARY_900 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_800 ? { 800: process.env.NEXT_PUBLIC_THEME_SECONDARY_800 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_700 ? { 700: process.env.NEXT_PUBLIC_THEME_SECONDARY_700 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_600 ? { 600: process.env.NEXT_PUBLIC_THEME_SECONDARY_600 } : {} },
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_500 ? { 500: process.env.NEXT_PUBLIC_THEME_SECONDARY_500 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_400 ? { 400: process.env.NEXT_PUBLIC_THEME_SECONDARY_400 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_300 ? { 300: process.env.NEXT_PUBLIC_THEME_SECONDARY_300 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_200 ? { 200: process.env.NEXT_PUBLIC_THEME_SECONDARY_200 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_100 ? { 100: process.env.NEXT_PUBLIC_THEME_SECONDARY_100 } : {} }, 
            ...{ ... process.env.NEXT_PUBLIC_THEME_SECONDARY_50 ? { 50: process.env.NEXT_PUBLIC_THEME_SECONDARY_50 } : {} }       
        }
    }
});

export default FvTheme;
export { FvTheme };

