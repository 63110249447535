import {
    SHARED_API_URLS,
    paginateUrl,
    type DataReturnType,
    type ErrorsReturnType,
    type LinkProps,
    type PaginationReturnType,
    type PaginationUrlProps
} from 'shared/services/api_base';


const API_BASE_URL = `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND}/api`;


const SHORT_API_URLS: { [key: string | symbol]: string } = {
    ...SHARED_API_URLS,
    AUTH: 'public/auth/login',
    LOGOUT: 'public/auth/logout',
    REGISTER: 'public/auth/register',
    SEARCH: 'public/search',
    ESTABLISHMENTS: 'public/establishments',
    POIS: 'public/pois',
    VADROUILLES: 'public/vadrouilles',
    FILTER: 'public/search/filters',
    SEARCH_POIS: 'public/search/pois',
    SEARCH_VADROUILLES: 'public/search/vadrouilles',
    SUB_THEMES: 'public/sub-themes',
    TAGS: 'public/tags',
    FRENCHVADROUILLES_TAGS: 'public/frenchvadrouilles-tags',
    DESTINATIONS: 'public/destinations',
    CITIES: 'public/cities',
    HOME: 'public/home',
    DETAILED_FILTERS: 'public/search/filters/init',
    FAVORITES_POIS: 'public/pois/favorites',
    FAVORITES_VADROUILLES: 'public/vadrouilles/favorites',
    //LANGUAGES: `public/languages`
    ORDERS: 'public/orders',
    CART: 'public/cart',
    CGV: 'cgv',
    CONTACT: 'public/contact',
    ALL_CGV: 'public/all-cgv',
    MY_VADROUILLES : 'public/my-vadrouilles',
    THEMES: 'public/themes',
    RECEPTION_VADROUILLES: 'public/reception-vadrouilles'
};

const API_URLS: { [key: keyof typeof SHORT_API_URLS]: string } = new Proxy(SHORT_API_URLS, {
    get : (obj, prop) => `${API_BASE_URL}/${obj[prop]}`
});

interface ServiceProps<T extends DataReturnType> {
    url: string,
    fetcher: (props?: any) => any,
    data?: T,
    options?: DataReturnType
}

const mockNetworkCall = ({ ...props }): Promise<DataReturnType> => {
    return new Promise((resolve) => resolve({}));
};


export {
    API_BASE_URL, API_URLS, mockNetworkCall, paginateUrl
};
export type {
    DataReturnType, ErrorsReturnType, LinkProps,
    PaginationReturnType, PaginationUrlProps, ServiceProps
};

